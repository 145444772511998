import imgBlocco1 from '../../src/images/data/Broadband_Options_Retail_App_Assets_IT_Desktop___1.png';
import imgBlocco2 from '../../src/images/data/Broadband_Options_Retail_App_Assets_IT_Desktop___2.png';
import imgBlocco3 from '../../src/images/data/Broadband_Options_Retail_App_Assets_IT_Desktop___3-2.png';
import imgBlocco4 from '../../src/images/data/Broadband_Options_Retail_App_Assets_IT_Desktop___4.png';
import imgBlocco5 from '../../src/images/data/Broadband_Options_Retail_App_Assets_IT_Desktop___5.png';
import imgBlocco6 from '../../src/images/data/Broadband_Options_Retail_App_Assets_IT_Desktop___6.png';

import img1 from '../../src/images/data/broadband-1.png';
import img2 from '../../src/images/data/broadband-2.png';
import img3 from '../../src/images/data/broadband-3.png';
import img4 from '../../src/images/data/bb-graph-2.png';

import logo from '../../src/images/data/logo-bb.png';

const q = {
    data : {
        wp: {
            broadband: {
                dataHeader : {
                    home:true, 
                    back:true,
                    logo:logo,
                },
                options : [
                    {
                        id: 'q-apps',
                        text : '<h1>Get all of this, at no extra cost?</h1><p>Check out everything you get as standard when you take one of our Fibre Broadband packages.</p>',
                        blocks : [
                            {
                                bg: '#F2F2F2',
                                img : imgBlocco1,
                                title : 'Speed Guarantee',
                                text : 'Get the speed we promised or money back.',
                            },
                            {
                                bg: '#F2F2F2',
                                img : imgBlocco2,
                                title : 'Wall to Wall WiFi Guarantee',
                                text : 'Get Wall to Wall WiFi in every room of your home or money back.',
                            },
                            {
                                bg: '#F2F2F2',
                                img : imgBlocco3,
                                title : 'Award-winning service',
                                text : 'Our award-winning customer service has received gold in the 2022 Choose Broadband awards.',
                            },
                            {
                                bg: '#F2F2F2',
                                img : imgBlocco4,
                                title : 'Security built in',
                                text : ' Sky Broadband Shield offers advanced protection with Safe Search included, to help make the internet a safer place for your whole family at no extra cost.',
                            },
                        ],
                        image: {
                            mediaItemUrl:img1
                        },
                        // vimeoId: '755043986',
                        vimeoId: '755135590',
                    },
                    {
                        id: 'q-ent',
                        text : '<h1>Go 25x faster with Sky Broadband Gigafast</h1><p>We’re rolling out game-changing speeds, 25x faster than our standard fibre​</p>',
                        blocks : [
                            {
                                bg: '#F2F2F2',
                                img : imgBlocco6,
                                title : 'Game-changing speed',
                                text : 'With an average download speed of 900Mb/s to the hub​',
                            },
                            {
                                bg: '#F2F2F2',
                                img : imgBlocco3,
                                title : 'Connect over 100 devices',
                                text : 'Stream in UHD, game online in 4k and video call all at same time',
                            },
                            {
                                bg: '#F2F2F2',
                                img : imgBlocco5,
                                title : 'Ultra reliable full fibre broadband',
                                text : 'Full fibre is the UK’s most reliable broadband technology​',
                            },
                        ],
                        image: {
                            mediaItemUrl:img2
                        },
                    },
                    {
                        id: 'q-ent3',
                        text : '',
                        blocks : [],
                        image: {
                            mediaItemUrl:img4
                        },
                    },
                    {
                        id: 'q-hd',
                        text : '<h1>Switching is simple</h1><p>Remote switching is available in most cases without an engineer. Our Switch Squad will keep you connected to your current provider right up until your switch.</h6>',
                        blocks : [
                            {
                                bg: '#F2F2F2',
                                title : 'Ready',
                                text : 'We\'ll confirm your new activation date when you select your broadband. And we\'ll pop your hub in the post.',
                            },
                            {
                                bg: '#F2F2F2',
                                title : 'Steady',
                                text : 'We\'ll contact your provider to cancel your contract for you.',
                            },
                            {
                                bg: '#F2F2F2',
                                title : 'Go',
                                text : 'Unplug your old hub and plug in your new one.',
                            },
                        ],
                        image: {
                            mediaItemUrl:img3
                        },
                        vimeoId: '755135590',
                    },
                ]
            }
        },

    }
}

export default q;