import React from "react";
import Carousel from "../../components/editorial/CarouselVideo";
import data from '../../../queries/data/broadband';
import Layout from "../../UI/Layout";

const index = () => {

  const carousel = {
    mainCarousel : [
      ...data.data.wp.broadband.options
    ]
  }

  const dataHeader = data.data.wp.broadband.dataHeader;

  console.log(dataHeader);

  return (
    <Layout className="Editorial broadBand" dataHeader={dataHeader}>
      <Carousel
      slideType="withBlocks"
      noScroll 
      noTitle 
      info={carousel}
      />
    </Layout>
  )
}

export default index;